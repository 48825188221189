<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Política de privacidad</h1>
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/generandorlq/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Respetamos la privacidad de todos los que visitan este sitio web y nos comprometemos a proteger su información personal. Esta política de privacidad se aplica a todos los visitantes de nuestro sitio web y a todos nuestros afiliados.

Al utilizar este sitio web, usted reconoce y acepta esta Política de privacidad. Sin embargo, si se opone a nuestro uso, procesamiento y transferencia de su información de alguna manera, no comparta su información en el sitio web.

Tipos de información personal recopilada
Podemos recopilar información personal como se describe a continuación con el fin de proporcionar servicios:

Información que puede proporcionar directamente, como por ejemplo:
Información de identificación: nombre, sexo, dirección residencial/postal, número de teléfono, fecha de nacimiento, estado civil, dirección de correo electrónico u otra información de contacto.
PAN, estado KYC, firma y fotografía.
Detalles de la cuenta bancaria u otro instrumento de pago.
Cualquier otro detalle que podamos necesitar para prestar el servicio.
Información que podemos recopilar cuando utiliza nuestros servicios, como por ejemplo:
Información de transacciones: solo leemos, recopilamos y monitoreamos mensajes de transacciones financieras para describir las transacciones y los montos correspondientes para evaluar el riesgo crediticio. No se accede a otros datos de SMS.
Información almacenada: podemos facilitar que los usuarios descarguen y muestren información, como detalles de confianza del plan, para referencia de los usuarios o carguen documentos relevantes en diversos procesos, como la gestión, la gestión de cuentas de usuario o la realización de órdenes comerciales.
Información de medios: facilitamos a los usuarios la recopilación/carga de documentos relevantes que pueden necesitar cargarse durante el proceso de administración de cuentas de usuario o de realización de órdenes comerciales.
Información del dispositivo: cuando visita nuestros Servicios, recopilamos cierta información sobre su dispositivo, incluida la capacidad de almacenamiento, el modelo de hardware, el sistema operativo y la versión, los identificadores únicos del dispositivo, la información de la red móvil y la información sobre su dispositivo y nuestras interacciones con los Servicios.
Cuando selecciona un contacto como referencia durante el proceso de préstamo, leemos la información del nombre y el número de teléfono. No subimos su lista de contactos a nuestros servidores.
La información del archivo de registro se almacena automáticamente:
Si visita o inicia sesión en nuestro sitio web únicamente para navegar, leer páginas o descargar información, cierta información sobre su visita se almacenará automáticamente en nuestro sistema. Esta información no puede identificarlo personalmente y no lo identificará.

Los tipos de información recopilada automáticamente incluyen, entre otros:

El tipo de navegador que utiliza (por ejemplo, Internet Explorer, Firefox, etc.),
El tipo de sistema operativo que está utilizando (como Windows o Mac OS) y
El nombre de dominio de su proveedor de servicios de Internet, la fecha y hora de su visita y las páginas de nuestro sitio web.
A veces utilizamos esta información para mejorar el diseño y el contenido de nuestro sitio web, principalmente para brindarle una mejor experiencia de navegación.

Esta política no pretende ni crea ningún derecho contractual ni legal de otro tipo para ninguno de nuestros usuarios o espectadores ni en nombre de ninguna otra parte.

Propósito de recopilar y utilizar la información.
En nuestro sitio web, solo recopilamos, conservamos y utilizamos su información cuando tenemos motivos para creer que su información ayudará a administrar nuestro negocio o le proporcionaremos productos, servicios y otras oportunidades. Esta información se recopila para fines comerciales específicos, tales como:

Para brindarle los servicios que pueda necesitar,
Procesar sus solicitudes de transacciones financieras y no financieras,
realizar investigaciones y análisis para proporcionar o mejorar nuestros servicios,
para revisar y procesar solicitudes que pueda enviar para aprovechar cualquier servicio financiero,
compartir con usted cualquier actualización/cambio en el Servicio y sus términos y condiciones,
aceptar e investigar cualquier queja/queja/disputa,
Respondiendo a sus preguntas y comentarios enviados,
para verificar su identidad y otros parámetros,
Para cumplir con los requisitos de las leyes/regulaciones aplicables y/o órdenes judiciales/directivas regulatorias que recibimos.
Divulgación de información
La información que usted proporcione podrá ser divulgada a:

Retener información
No retendremos ni almacenaremos dicha información por más tiempo del necesario para ese propósito, a menos que pueda usarse legalmente o sea requerido por cualquier otra ley por el momento.

Al aceptar utilizar los servicios que brindamos, también acepta que recopilemos y usemos su información o datos personales confidenciales. Siempre tiene derecho a rechazar o retirar su consentimiento para compartir/difundir su información o datos personales sensibles comunicándose con el servicio de atención al cliente. Sin embargo, si opta por no participar o retira sus datos personales, no podrá aprovechar al máximo ninguno de nuestros servicios.

Comunicaciones y anuncios.
Cuando utiliza el Sitio o nos envía correo electrónico u otros datos, información o comunicaciones, acepta y comprende que se está comunicando con nosotros electrónicamente y acepta recibir dichas comunicaciones periódicamente de nuestra parte. Podemos enviarle un aviso por correo electrónico o por escrito o publicando dicho aviso de manera destacada en nuestros sitios web. Puede optar por no recibir ciertos métodos de notificación como mejor le parezca.

Actualiza o revisa tu información
Puede revisar los datos personales o la información que nos ha proporcionado previa solicitud por escrito. IIFL se asegurará de que cualquier información personal o datos personales sensibles o información que se considere inexacta o errónea se corrija o modifique cuando sea posible.

Practique una seguridad razonable para proteger su información.
Utilizamos medidas de seguridad físicas, administrativas y técnicas comercialmente razonables para proteger la integridad y seguridad de su información personal. Esto incluye una revisión interna de nuestras prácticas de recopilación, almacenamiento y procesamiento de datos, así como de nuestras medidas de seguridad, como medidas de seguridad física apropiadas y cifrado, para evitar el acceso no autorizado a nuestros sistemas donde se almacenan datos personales.

Toda la información recopilada en los sitios web anteriores se almacena de forma segura en bases de datos controladas por nosotros. La base de datos se almacena en un servidor seguro; El acceso a él está protegido por contraseña y estrictamente limitado.

Para proteger su privacidad y seguridad, tomamos medidas razonables (como solicitar una contraseña única) para verificar su identidad antes de otorgarle acceso a su cuenta. Usted es responsable de mantener la confidencialidad de su cuenta única y la información de su contraseña y de controlar nuestro acceso a sus comunicaciones por correo electrónico en todo momento.

Aunque utilizamos medidas de seguridad para ayudar a proteger su información personal contra divulgación no autorizada, mal uso o alteración (como lo hacen todas las redes informáticas conectadas a Internet), no podemos garantizar la seguridad de la información que usted nos transmite. riesgo. Una vez que recibamos la información que usted transmite, haremos todos los esfuerzos comercialmente razonables para garantizar la seguridad de esa información.

Enlaces a otros sitios web
Tenga en cuenta que esta Política de privacidad no se aplica a sitios web de terceros vinculados a este sitio web. No somos responsables del contenido y las prácticas de privacidad de dichos sitios vinculados. Debe leer la declaración de privacidad de cada sitio web vinculado antes de compartir cualquier información.

Cambios en nuestra política de privacidad
Tenga en cuenta que esta política puede cambiar con el tiempo. Si cambiamos nuestras políticas y procedimientos de privacidad, publicaremos esos cambios en nuestro sitio web para notificárselo. Los cambios a esta política entran en vigencia a partir de la fecha en que se publican en esta página. Visite nuestro sitio web para conocer cualquier cambio en esta política.

Quejarse:
Cualquier diferencia y queja con respecto al procesamiento y uso de su información puede plantearse a nuestro oficial de quejas designado. Para obtener más detalles, visite Procedimientos de resolución de quejas.`,

    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3200px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -350px auto 0;
  box-shadow:0 4px 40px rgba(0, 0, 0, 0.05);
}

.title-line{
  width: 430px;
  border: 5px solid #1ADD4C;
  margin: 0 490px;
}
</style>